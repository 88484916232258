<template>
    <div class="container">
        <div class="text-center mt-15">
            <span style="font-size: 24px">เปลี่ยนรหัสผ่านใหม่</span>
        </div>
        <b-row>
            <b-col sm="0" md="2" lg="3" xl="3"></b-col>
            <b-col sm="12" md="8" lg="6" xl="6" class="body-form-oscc main-form-oscc py-10">
                <b-row>
                    <b-col>
                        <b-form-group
                                label-for="input4"
                                class="mb-0"
                        >
                            <template #label>
                                รหัสผ่านใหม่<span class="star-red">*</span>
                            </template>
                            <template #description>
                                <span :style="max_min_length_password?'display:block':'display:none'" style="font-size: 11px;color: red">*มีความยาวระหว่าง 6-10 ตัวอักษร</span>
                                <span :style="upper_password?'display:block':'display:none'" style="font-size: 11px;color: red">*ตัวอักษรภาษาอังกฤษตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร</span>
                                <span :style="lower_password?'display:block':'display:none'" style="font-size: 11px;color: red">*ตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร</span>
                                <span :style="integer_password?'display:block':'display:none'" style="font-size: 11px;color: red">*ตัวอักษรเลขอย่างน้อย 1 ตัวอักษร</span>
                            </template>
                            <b-form-input
                                    id="input4"
                                    v-model="register.password"
                                    class="input-form-oscc"
                                    placeholder="ระบุรหัสผ่าน"
                                    :maxlength="10"
                                    v-on:input="check_valid_password()"
                                    type="password"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                                label-for="input5"
                                class="mb-0"
                        >
                            <template #label>
                                ยืนยันรหัสผ่านอีกครั้ง<span class="star-red">*</span>
                            </template>
                            <b-form-input
                                    id="input5"
                                    v-model="register.re_password"
                                    class="input-form-oscc"
                                    placeholder="ระบุรหัสผ่านอีกครั้ง"
                                    :maxlength="30"
                                    type="password"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="0" md="2" lg="3" xl="3"></b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col sm="0" md="2" lg="3" xl="3"></b-col>
            <b-col sm="12" md="8" lg="6" xl="6">
                <b-button class="btn-oscc" variant="outline-secondary" @click="showAlert">ยกเลิก</b-button>
                <b-button class="btn-submit-oscc float-right" variant="primary" @click="submitData">ยืนยัน</b-button>
            </b-col>
            <b-col sm="0" md="2" lg="3" xl="3"></b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "ResetPassword",
        props:["token"],
        data(){
            return{
                upper_password : true,
                lower_password : true,
                integer_password : true,
                max_min_length_password : true,
                register: {
                    password: '',
                    re_password: '',
                },
            }
        },
        created() {
        },
        methods:{
            check_valid_password() {
                if (this.register.password.length >= 6 && this.register.password.length <= 10){
                    this.max_min_length_password = false;
                }else{
                    this.max_min_length_password = true;
                }
                if (this.isUpper(this.register.password)){
                    this.upper_password = false;
                }else{
                    this.upper_password = true;
                }
                if (this.isLower(this.register.password)){
                    this.lower_password = false;
                }else{
                    this.lower_password = true;
                }
                if (this.isNumber(this.register.password)){
                    this.integer_password = false;
                }else{
                    this.integer_password = true;
                }
            },
            isUpper(str) {
                return /[A-Z]/.test(str);
            },
            isLower(str) {
                return /[a-z]/.test(str);
            },
            isNumber(str) {
                return /[0-9]/.test(str);
            },
            showAlert(){
                this.$swal.fire({
                    title: 'ยกเลิกการทำรายการ ?',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่',
                    cancelButtonText: 'ไม่ใช่'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/')
                    }
                })
            },
            submitData(){
                let validator = this.validate();
                if (validator){
                    this.$swal.fire({
                        icon: 'error',
                        title: validator,
                    })
                }else{
                    this.showLoading();
                    let data = {
                        password : this.register.password ? this.register.password : '',
                        token : this.token ? this.token : '',
                    }
                    this.$store.dispatch('Auth/rePassword',data).then(res=>{
                        if(res.message === "เปลี่ยนรหัสผ่านใหม่สำเร็จ"){
                            this.$swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: res.message,
                                showConfirmButton: false,
                                timer: 3000,
                                willClose: () => {
                                    this.$router.push('/')
                                }
                            })
                        }else{
                            this.$swal.fire({
                                icon: 'error',
                                title: res.message
                            })
                        }
                    })
                }
            },
            validate(){
                let self = this;
                let arr_validate = [
                    're_password','password'
                ];
                let arr_validate_th = [
                    'รหัสผ่านอีกครั้ง','รหัสผ่าน'
                ];
                let checker = 1;
                let failed_key = '';
                Object.keys(this.register).forEach(function(key){
                    if (!self.register[key] && arr_validate.includes(key) && checker == 1){
                        checker = 0;
                        failed_key = key;
                    }
                });
                if (this.max_min_length_password || this.upper_password || this.lower_password || this.max_min_length_password) return 'รูปแบบรหัสผ่านไม่ถูกต้อง';
                else if (checker && this.register['password'] && this.register['re_password'] && !this.register['password'].includes(this.register['re_password'])) return 'รหัสผ่านไม่ตรงกัน';
                else if (checker) return false;
                else return 'กรุณาระบุ ' + arr_validate_th[arr_validate.indexOf(failed_key)];
            },
            showLoading(){
                this.$loading.show({background:
                        this.$swal.fire({
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                this.$swal.showLoading()
                            }
                        })
                    ,animation : true});
            },
            hideLoading(){
                this.$loading.hide();
                this.$swal.hideLoading()
            },
        }
    }
</script>

<style scoped>
    .main-form-oscc{
        background: #FFFFFF;
        border: 0.5px solid #A8A8A8;
        box-sizing: border-box;
        box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
        border-radius: 5px;
    }
    .body-form-oscc{
        padding-left: 7%;
        padding-right: 6%;
        margin-top: 13px;
    }
    .star-red{
        color: red;
    }
    .btn-oscc{
        font-size: 16px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 50px 10px 50px;
    }
    .btn-submit-oscc{
        font-size: 16px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 100px 10px 100px;
    }
</style>
